<template>
  <div>
    <v-app>
      <navigation :color="color" :flat="flat" :textColor="textColor" :logoCompleto="logoCompleto" />
      <v-main>
        <v-carousel
          v-if="itemsAboutUs.about_us_banner"
          hide-delimiters
          :show-arrows="false"
        >
          <v-carousel-item
          >
            <v-img
              :src="itemsAboutUs.about_us_banner.url"
              contain
            />
          </v-carousel-item>
        </v-carousel>

        <v-row class="my-10" justify="center">
          <v-col cols="12" sm="4" md="2" class="text-center">
            <v-img class="mx-auto" src="~@/assets/img/about_mission.png"></v-img>
          </v-col>
        </v-row>
        <v-row class="mb-10" justify="center">
          <v-col cols="12" sm="4" md="4" class="text-center">
            <!-- <p class="font-weight-light text-left">
              MediAssist está comprometido con hacer accesibles los servicios de salud a toda la población.
            </p>
            <p class="font-weight-light text-left">
              Para ello buscamos conectar a los paciontes y médicos de una manera eficiente y transparente.
            </p>
            <p class="font-weight-light text-left">
              Brindamos soluciones de financiamiento en línea a los consumidores para servicios médicos y procedimientos estéticos.
            </p>
            <p class="font-weight-light text-left">
              Ofrecemos una línea de crédito familiar que permite mantener protegida la salud de tu familia, pues permite diferir el costo de sus procedimientos médicos y estéticos a través del tiempo en pequeños pagos quincenales o mensuales.
            </p> -->
            <p class="font-weight-light text-left">
              {{ itemsAboutUs.about_us_mission }}
            </p>
          </v-col>
        </v-row>


        <v-row justify="space-around">
          <v-col cols="12" sm="12" md="8">
            <v-row no-gutters justify="center">
                <v-col
                  v-for="(card, i) in itemsAboutUs.about_us_items"
                  :key="i"
                  cols="12"
                  sm="6"
                  md="6"
                  class="text-center card-hover"
                >
                  <v-card
                    color="transparent"
                    class="ma-2"
                    data-aos="flip-left"
                    flat
                    :data-aos-delay="500+(i*100)"
                    :loading="loading"
                    @click="showDialog = true"
                  >
                    <v-row no-gutters justify="center">
                      <v-col cols="4">
                        <v-img
                          :src="card.image_url"
                          class="white--text align-end"
                          contain
                          max-height="120"
                        >
                          <!-- <v-card-title>{{ card.element_name }}</v-card-title> -->
                        </v-img>
                      </v-col>
                      <v-col cols="5">
                        <!-- <div v-html="card.element_content"></div> -->
                        <p class="text-h6 secondary--text">{{ card.element_name }}</p>
                        <p class="text-center text-subtitle-1 font-weight-light" v-html="card.element_content"></p>
                      </v-col>
                    </v-row>
                    
                  </v-card>
                </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-main>
      <v-scale-transition>
        <v-btn
          fab
          v-show="fab"
          v-scroll="onScroll"
          dark
          fixed
          bottom
          right
          color="secondary"
          @click="toTop"
        >
          <v-icon>mdi-arrow-up</v-icon>
        </v-btn>
      </v-scale-transition>
      <customFooter></customFooter>
    </v-app>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import AOS from 'aos';
import navigation from "@/modules/landing/components/Navigation.vue";
import customFooter from "@/modules/shared/layouts/Footer.vue";

import about_header from '@/assets/img/about_header.png';
import maternidad from '@/assets/img/about_calendar.png';
import estetica from '@/assets/img/about_call.png';
import alopecia from '@/assets/img/about_list.png';
import dermatologia from '@/assets/img/about_checked.png';

export default {
  name: "Nosotros",
  components: {
    navigation,
    
    customFooter,
  },

  data: () => ({
    logoCompleto: null,
    fab: null,
    color: "primary",
    textColor: "white",
    flat: null,
    itemsCarousel: [
      {
        src: about_header,
      },
    ],
    cardsServicios: [
      { 
        title: 'Pago en mensualidades',
        descripcion: 'Plazo de <span class="font-weight-bold secondary--text">3, 6 y 12</span> meses, desde $2,500mxn mensuales.',
        src: maternidad,
        flex: 6 
      },
      {
        title: 'Información',
        descripcion: 'Con nuestra red de Médicos afiliados a MediAssist',
        src: estetica,
        flex: 6
      },
      {
        title: 'Autorización',
        descripcion: '<span class="font-weight-bold secondary--text">48hrs.</span> de autorización para plan Maternidad',
        src: alopecia,
        flex: 6
      },
      {
        title: 'Requisitos',
        descripcion: 'Identificación oficial. Comprobante de domicilio, CURP, llenar solicitud para aprobación y autorización de instrucciones de pago.',
        src: dermatologia,
        flex: 6
      },
    ],
  }),

  created() {
    this.getItemsAboutUs();
    const top = window.pageYOffset || 0;
    if (top <= 60) {
      this.color = "primary";
      this.flat = true;
    }
  },
  computed: {
    ...mapState("loader", ["loading"]),
    ...mapState("landing", ["itemsAboutUs"]),
  },
  mounted() {
    // Actualiza AOS después de que Vue ha renderizado el componente
    this.$nextTick(() => {
      AOS.refresh();
    });
  },
  methods: {
    ...mapActions({
      getItemsAboutUs: "landing/getItemsAboutUs",
    }),
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 60;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
  watch: {
    fab(value) {
      if (value) {
        this.color = "dark";
        this.textColor = "white";
        this.flat = false;
        this.logoCompleto = false;
      } else {
        this.color = "primary";
        this.textColor = "white";
        this.flat = true;
        this.logoCompleto = true;
      }
    },
  }
};
</script>

<style scoped>
#app {
  background-image: url("~@/assets/img/home_background.png");
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  z-index: 10;
}
</style>